package it.hopin.main

import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.spring
import androidx.compose.animation.core.tween
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.RowScope
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.safeDrawing
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.systemBars
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.foundation.pager.PagerState
import androidx.compose.foundation.pager.VerticalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.ClickableText
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Text
import androidx.compose.material.ripple.rememberRipple
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.PointerIcon
import androidx.compose.ui.input.pointer.pointerHoverIcon
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.semantics.Role
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.min
import hopinit.composeapp.generated.resources.Res
import hopinit.composeapp.generated.resources.arrow_down
import hopinit.composeapp.generated.resources.download_on_the_appstore_button
import hopinit.composeapp.generated.resources.lanker_description
import hopinit.composeapp.generated.resources.logo_lanker
import hopinit.composeapp.generated.resources.logo_onkey
import hopinit.composeapp.generated.resources.logo_trip
import hopinit.composeapp.generated.resources.onkey_description
import hopinit.composeapp.generated.resources.privacy_policy
import hopinit.composeapp.generated.resources.support_email
import hopinit.composeapp.generated.resources.terms_and_conditions
import hopinit.composeapp.generated.resources.trip_description
import it.hopin.main.style.HopinTheme
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.ui.tooling.preview.Preview

data class AppItem(
    val id: Int,
    val title: String,
    val image: DrawableResource,
    val description: StringResource,
    val color: Color,
    val appStoreReference: String,
    val privacyPolicyLink: String,
    val termsAndConditionsLink: String,
)

val appPagedItems: List<AppItem> =
    listOf(
        AppItem(
            0,
            "Hopin.it",
            Res.drawable.logo_trip,
            Res.string.trip_description,
            Color(0xFF171717),
            "",
            "",
            ""
        ),
        AppItem(
            1,
            "Trip",
            Res.drawable.logo_trip,
            Res.string.trip_description,
            Color(0xFF171717),
            "https://itunes.apple.com/app/id1552263326",
            "https://snap.hopin.it/documents/privacy_policy.html",
            "https://snap.hopin.it/documents/terms.html"
        ),
        AppItem(
            2,
            "Lanker",
            Res.drawable.logo_lanker,
            Res.string.lanker_description,
            Color(0xFF171717),
            "https://itunes.apple.com/app/id1602126141",
            "https://lanker.hopin.it/documents/privacy-policy-dark.html",
            "https://lanker.hopin.it/documents/terms-and-conditions-light.html"
        ),
        AppItem(
            3,
            "OnKey",
            Res.drawable.logo_onkey,
            Res.string.onkey_description,
            Color(0xFF171717),
            "https://itunes.apple.com/app/id6478709879",
            "https://keyboard.hopin.it/documents/privacy_policy.pdf",
            "https://keyboard.hopin.it/documents/terms_of_use.pdf"
        ),
    )

@Composable
@Preview
fun App() {
    HopinTheme {
        BoxWithConstraints(
            contentAlignment = Alignment.TopEnd,
            modifier = Modifier.windowInsetsPadding(WindowInsets.safeDrawing).fillMaxSize().background(MaterialTheme.colors.background)
        ) {
            PagedContent()
            BottomSupportButton()
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PagedContent() {
    val pagerState = rememberPagerState(0, pageCount = { appPagedItems.size })
    VerticalPager(pagerState) { page ->
        val item = appPagedItems[page]
        if (item.id == 0) {
            HopinITMain(item, pagerState)
        } else {
            AppPage(item)
        }
    }
}

@Composable
fun BottomSupportButton() {
    val uriHandler = LocalUriHandler.current
    val email = stringResource(Res.string.support_email)
    Column(modifier = Modifier.fillMaxHeight(), verticalArrangement = Arrangement.Bottom) {
        Spacer(Modifier.weight(1f))
        ClickableText(
            modifier = Modifier
                .pointerHoverIcon(PointerIcon.Hand)
                .padding(12.dp)
                .background(MaterialTheme.colors.surface, shape = RoundedCornerShape(12.dp))
                .heightIn(min = 14.dp)
                .padding(12.dp),
            text = buildAnnotatedString {
                pushStyle(SpanStyle(color = MaterialTheme.colors.primary))
                append(email)
            },
            onClick = { uriHandler.openUri("mailto:$email") }
        )
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
private fun HopinITMain(item: AppItem, pagerState: PagerState) {
    val scope = rememberCoroutineScope { Dispatchers.Main.immediate }
    val ripple = rememberRipple(bounded = false, radius = 38.dp)
    val interactionSource = remember { MutableInteractionSource() }
    val infiniteTransition = rememberInfiniteTransition()
    val animatedY by infiniteTransition.animateFloat(
        initialValue = -10f,
        targetValue = 10f,
        animationSpec = infiniteRepeatable(
            animation = tween(durationMillis = 1500, easing = LinearEasing),
            repeatMode = RepeatMode.Reverse
        ),
    )

    Box(
        modifier = Modifier.fillMaxSize().background(item.color),
        contentAlignment = Alignment.Center
    ) {
        Text(
            text = item.title,
            style = MaterialTheme.typography.h1,
            fontWeight = FontWeight.SemiBold,
            color = MaterialTheme.colors.onSurface
        )
        Column {
            Spacer(Modifier.weight(1f))
            Image(
                painter = painterResource(Res.drawable.arrow_down),
                contentDescription = "Arrow indicates that scroll down",
                modifier = Modifier
                    .offset(y = animatedY.dp)
                    .padding(bottom = 46.dp)
                    .size(40.dp, 40.dp)
                    .pointerHoverIcon(PointerIcon.Hand)
                    .clickable(interactionSource = interactionSource, indication = ripple) {
                        scope.launch { pagerState.animateScrollToPage(1, animationSpec = spring(stiffness = 100F)) }
                    }
            )
        }
    }
}

@Composable
fun AppPage(item: AppItem) {
    BoxWithConstraints(
        Modifier
            .background(item.color)
            .fillMaxSize()
            .windowInsetsPadding(WindowInsets.systemBars)
            .padding(16.dp),
        contentAlignment = Alignment.Center,
    ) {
        Row(
            modifier = Modifier
                .background(Color.White, shape = RoundedCornerShape(24.dp))
                .padding(16.dp)
                .widthIn(max = 960.dp),
            horizontalArrangement = Arrangement.spacedBy(16.dp)
        ) {
            LeftSide(item)
            RightSide(item)
        }
    }
}

@Composable
private fun RowScope.RightSide(item: AppItem) {
    Column(
        modifier = Modifier.weight(3f),
        verticalArrangement = Arrangement.spacedBy(12.dp)
    ) {
        Text(item.title, style = MaterialTheme.typography.h4)
        Text(
            text = stringResource(item.description),
            style = MaterialTheme.typography.body1,
            overflow = TextOverflow.Ellipsis,
        )
    }
}

@Composable
private fun RowScope.LeftSide(item: AppItem) {
    val uriHandler = LocalUriHandler.current
    val ripple = rememberRipple()
    val interactionSource = remember { MutableInteractionSource() }
    Column(
        modifier = Modifier.weight(1f),
        verticalArrangement = Arrangement.spacedBy(12.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Image(
            painter = painterResource(item.image),
            contentDescription = "App logo",
            modifier =
            Modifier
                .aspectRatio(1f)
                .clip(RoundedCornerShape(24.dp))
                .shadow(12.dp)
        )
        Column(verticalArrangement = Arrangement.spacedBy(6.dp), horizontalAlignment = Alignment.CenterHorizontally) {
            ClickableText(
                AnnotatedString(
                    text = stringResource(Res.string.privacy_policy),
                    paragraphStyle = ParagraphStyle(textAlign = TextAlign.Center),
                    spanStyle = SpanStyle(textDecoration = TextDecoration.Underline, color = Color(0xFF22B1EE))
                ), onClick = { uriHandler.openUri(item.privacyPolicyLink) },
                style = MaterialTheme.typography.caption,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier.pointerHoverIcon(PointerIcon.Hand)
            )
            ClickableText(
                AnnotatedString(
                    text = stringResource(Res.string.terms_and_conditions),
                    paragraphStyle = ParagraphStyle(textAlign = TextAlign.Center),
                    spanStyle = SpanStyle(textDecoration = TextDecoration.Underline, color = Color(0xFF22B1EE))
                ),
                style = MaterialTheme.typography.caption,
                overflow = TextOverflow.Ellipsis,
                onClick = { uriHandler.openUri(item.termsAndConditionsLink) },
                modifier = Modifier.pointerHoverIcon(PointerIcon.Hand)
            )
        }
        Image(
            painter = painterResource(Res.drawable.download_on_the_appstore_button),
            contentDescription = "Download ${item.title} on the AppStore",
            modifier = Modifier
                .pointerHoverIcon(PointerIcon.Hand)
                .clickable(
                    interactionSource = interactionSource,
                    indication = ripple,
                    role = Role.Button
                ) { uriHandler.openUri(item.appStoreReference) },
        )
    }
}
//            AnimatedVisibility(showContent) {
//                val greeting = remember { Greeting().greet() }
//                Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
//                    Image(painterResource(Res.drawable.compose_multiplatform), null)
//                    Text("Compose: $greeting")
//                }
//            }
