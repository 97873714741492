package it.hopin.main.style

import androidx.compose.material.Colors
import androidx.compose.ui.graphics.Color

internal fun hopinColorScheme() = darkColors

val darkColors =
    Colors(
        primary = Color(0xFFBB86FC),
        primaryVariant = Color(0xFF3700B3),
        secondary = Color(0xFF03DAC5),
        secondaryVariant = Color(0xFF018786),
        background = Color(0xFF171717),
        surface = Color(0xFF121212),
        error = Color(0xFFCF6679),
        onPrimary = Color(0xFF000000),
        onSecondary = Color(0xFF000000),
        onBackground = Color(0xFFFFFFFF),
        onSurface = Color(0xFFFFFFFF),
        onError = Color(0xFF000000),
        isLight = false,
    )

val lightColors =
    Colors(
        primary = Color(0xFF6200EE),
        primaryVariant = Color(0xFF3700B3),
        secondary = Color(0xFF03DAC5),
        secondaryVariant = Color(0xFF018786),
        background = Color(0xFFFFFF),
        surface = Color(0xFF121212),
        error = Color(0xFFCF6679),
        onPrimary = Color(0xFF000000),
        onSecondary = Color(0xFF000000),
        onBackground = Color(0xFF000000),
        onSurface = Color(0xFFFFFFFF),
        onError = Color(0xFF000000),
        isLight = true,
    )