package it.hopin.main.style

import androidx.compose.material.Typography
import androidx.compose.runtime.Composable
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.sp
import hopinit.composeapp.generated.resources.NunitoSans_Bold
import hopinit.composeapp.generated.resources.NunitoSans_Light
import hopinit.composeapp.generated.resources.NunitoSans_Medium
import hopinit.composeapp.generated.resources.NunitoSans_Regular
import hopinit.composeapp.generated.resources.NunitoSans_SemiBold
import hopinit.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.Font

@Composable
internal fun HopinFontFamily() =
    FontFamily(
        Font(Res.font.NunitoSans_Light, weight = FontWeight.Light),
        Font(Res.font.NunitoSans_Regular, weight = FontWeight.Normal),
        Font(Res.font.NunitoSans_Medium, weight = FontWeight.Medium),
        Font(Res.font.NunitoSans_SemiBold, weight = FontWeight.SemiBold),
        Font(Res.font.NunitoSans_Bold, weight = FontWeight.Bold),
    )

@Composable
internal fun HopinTypography(): Typography {
    val fontFamily = HopinFontFamily()

    return Typography(
        h1 =
        TextStyle(
            fontSize = 57.sp,
            lineHeight = 64.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = (-0.25).sp,
        ),
        h2 =
        TextStyle(
            fontSize = 45.sp,
            lineHeight = 52.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.sp,
        ),
        h3 =
        TextStyle(
            fontSize = 36.sp,
            lineHeight = 44.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.sp,
        ),
        h4 =
        TextStyle(
            fontSize = 32.sp,
            lineHeight = 40.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.sp,
        ),
        h5 =
        TextStyle(
            fontSize = 28.sp,
            lineHeight = 36.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.sp,
        ),
        h6 =
        TextStyle(
            fontSize = 24.sp,
            lineHeight = 32.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.sp,
        ),
        //        titleLarge = TextStyle(
//            fontSize = 18.sp,
//            lineHeight = 24.sp,
//            fontFamily = fontFamily,
//            fontWeight = FontWeight.Medium,
//            letterSpacing = 0.sp
//        ),
        subtitle1 =
        TextStyle(
            fontSize = 16.sp,
            lineHeight = 24.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Medium,
            letterSpacing = 0.15.sp,
        ),
        subtitle2 =
        TextStyle(
            fontSize = 14.sp,
            lineHeight = 20.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Medium,
            letterSpacing = 0.1.sp,
        ),
        button =
        TextStyle(
            fontSize = 14.sp,
            lineHeight = 20.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.1.sp,
        ),
        //        labelMedium = TextStyle(
//            fontSize = 12.sp,
//            lineHeight = 16.sp,
//            fontFamily = fontFamily,
//            fontWeight = FontWeight.Normal,
//            letterSpacing = 0.5.sp
//        ),
        overline =
        TextStyle(
            fontSize = 11.sp,
            lineHeight = 16.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.5.sp,
        ),
        body1 =
        TextStyle(
            fontSize = 16.sp,
            lineHeight = 24.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.5.sp,
        ),
        body2 =
        TextStyle(
            fontSize = 14.sp,
            lineHeight = 20.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.25.sp,
        ),
        caption =
        TextStyle(
            fontSize = 12.sp,
            lineHeight = 16.sp,
            fontFamily = fontFamily,
            fontWeight = FontWeight.Normal,
            letterSpacing = 0.4.sp,
        ),
    )
}