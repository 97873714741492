package it.hopin.main.style

import androidx.compose.material.MaterialTheme
import androidx.compose.runtime.Composable


@Composable
fun HopinTheme(content: @Composable () -> Unit) {
    MaterialTheme(
        colors = hopinColorScheme(),
        typography = HopinTypography(),
        content = content,
    )
}
