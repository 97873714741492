@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package hopinit.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val assistance_needed: StringResource by 
      lazy { init_assistance_needed() }

  public val lanker_description: StringResource by 
      lazy { init_lanker_description() }

  public val onkey_description: StringResource by 
      lazy { init_onkey_description() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val support_email: StringResource by 
      lazy { init_support_email() }

  public val terms_and_conditions: StringResource by 
      lazy { init_terms_and_conditions() }

  public val trip_description: StringResource by 
      lazy { init_trip_description() }
}

internal val Res.string.assistance_needed: StringResource
  get() = CommonMainString0.assistance_needed

private fun init_assistance_needed(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:assistance_needed", "assistance_needed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 10,
    77),
    )
)

internal val Res.string.lanker_description: StringResource
  get() = CommonMainString0.lanker_description

private fun init_lanker_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:lanker_description", "lanker_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 88,
    2422),
    )
)

internal val Res.string.onkey_description: StringResource
  get() = CommonMainString0.onkey_description

private fun init_onkey_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onkey_description", "onkey_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 2511,
    2345),
    )
)

internal val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 4857,
    42),
    )
)

internal val Res.string.support_email: StringResource
  get() = CommonMainString0.support_email

private fun init_support_email(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:support_email", "support_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 4900,
    45),
    )
)

internal val Res.string.terms_and_conditions: StringResource
  get() = CommonMainString0.terms_and_conditions

private fun init_terms_and_conditions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_and_conditions", "terms_and_conditions",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 4946,
    52),
    )
)

internal val Res.string.trip_description: StringResource
  get() = CommonMainString0.trip_description

private fun init_trip_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:trip_description", "trip_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/values/strings.commonMain.cvr", 4999,
    1400),
    )
)
