@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package hopinit.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val arrow_down: DrawableResource by 
      lazy { init_arrow_down() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val download_on_the_appstore_button: DrawableResource by 
      lazy { init_download_on_the_appstore_button() }

  public val logo_lanker: DrawableResource by 
      lazy { init_logo_lanker() }

  public val logo_onkey: DrawableResource by 
      lazy { init_logo_onkey() }

  public val logo_trip: DrawableResource by 
      lazy { init_logo_trip() }
}

internal val Res.drawable.arrow_down: DrawableResource
  get() = CommonMainDrawable0.arrow_down

private fun init_arrow_down(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:arrow_down",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/drawable/arrow_down.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.download_on_the_appstore_button: DrawableResource
  get() = CommonMainDrawable0.download_on_the_appstore_button

private fun init_download_on_the_appstore_button(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:download_on_the_appstore_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/drawable/download_on_the_appstore_button.xml", -1, -1),
    )
)

internal val Res.drawable.logo_lanker: DrawableResource
  get() = CommonMainDrawable0.logo_lanker

private fun init_logo_lanker(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_lanker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/drawable/logo_lanker.png", -1, -1),
    )
)

internal val Res.drawable.logo_onkey: DrawableResource
  get() = CommonMainDrawable0.logo_onkey

private fun init_logo_onkey(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_onkey",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/drawable/logo_onkey.png", -1, -1),
    )
)

internal val Res.drawable.logo_trip: DrawableResource
  get() = CommonMainDrawable0.logo_trip

private fun init_logo_trip(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_trip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/drawable/logo_trip.png", -1, -1),
    )
)
