@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package hopinit.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val NunitoSans_Bold: FontResource by 
      lazy { init_NunitoSans_Bold() }

  public val NunitoSans_Light: FontResource by 
      lazy { init_NunitoSans_Light() }

  public val NunitoSans_Medium: FontResource by 
      lazy { init_NunitoSans_Medium() }

  public val NunitoSans_Regular: FontResource by 
      lazy { init_NunitoSans_Regular() }

  public val NunitoSans_SemiBold: FontResource by 
      lazy { init_NunitoSans_SemiBold() }
}

internal val Res.font.NunitoSans_Bold: FontResource
  get() = CommonMainFont0.NunitoSans_Bold

private fun init_NunitoSans_Bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NunitoSans_Bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/font/NunitoSans_Bold.ttf", -1, -1),
    )
)

internal val Res.font.NunitoSans_Light: FontResource
  get() = CommonMainFont0.NunitoSans_Light

private fun init_NunitoSans_Light(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NunitoSans_Light",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/font/NunitoSans_Light.ttf", -1, -1),
    )
)

internal val Res.font.NunitoSans_Medium: FontResource
  get() = CommonMainFont0.NunitoSans_Medium

private fun init_NunitoSans_Medium(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NunitoSans_Medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/font/NunitoSans_Medium.ttf", -1, -1),
    )
)

internal val Res.font.NunitoSans_Regular: FontResource
  get() = CommonMainFont0.NunitoSans_Regular

private fun init_NunitoSans_Regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NunitoSans_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/font/NunitoSans_Regular.ttf", -1, -1),
    )
)

internal val Res.font.NunitoSans_SemiBold: FontResource
  get() = CommonMainFont0.NunitoSans_SemiBold

private fun init_NunitoSans_SemiBold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NunitoSans_SemiBold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/hopinit.composeapp.generated.resources/font/NunitoSans_SemiBold.ttf", -1, -1),
    )
)
